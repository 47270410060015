app.factory('vlilleFactory', function($http) {
  var factory = {};
  var vlilles = null;

  factory.isDataLoaded = function() {
    return (vlilles === null) ? false : true;
  };

  factory.set = function(data) {
    vlilles = data;
  };

  factory.getAll = function() {
    return $http.get('https://metropole-europeenne-de-lille.opendatasoft.com/api/records/1.0/search/?dataset=vlille-realtime&rows=10000');
  };

  return factory;
});
